@import "~bootstrap/scss/bootstrap";

$darkblue: #235789;
$purple: #9BA2FF;
$jet: #343A40;
$grey: #8FAADC;
$blue: #568EA3;
$queen: #587792;
$morning: #93A29B;
$steelblue: #BBD1EA;
$platinum: #E0E0E2;
$lightgrey: #CED3DC;

html {
  max-width: 100vw;
  overflow-x: hidden; }

body {
  padding-top: 66px;
  min-height: 100vh;
  position: relative;
  padding-bottom: 110px;

  // font-family: 'Manrope', sans-serif
  // font-family: 'Raleway', sans-serif
  font-family: 'Baloo Tamma 2', sans-serif; }
.btn-link {
  color: $jet;
  &:hover {
    color: $darkblue; } }
a {
  color: $darkblue;
  &:hover {
    color: $purple; } }
.accordion {
  text-align: left; }
.card-header {
  background-color: $lightgrey;
  border: solid $queen .5px; }
.button {
  background-color: $grey;
  color: $jet;
  border-color: $grey;
  &:hover {
    background-color: $jet;
    color: $grey;
    border-color: $jet; } }
.test-accounts {
  border: 1px solid $jet;
  margin-right: 20px;
  margin-left: 20px;
  @include media-breakpoint-only(xs) {
    margin-right: 0;
    margin-left: 0; } }
.header {
  &--navbar {
    @include media-breakpoint-only(xs) {
      width: 100vw; } }
  &--icon {
    height: 30px;
    width: auto;
    margin-right: 5px; } }
.navbar-toggler {
  border: 0px; }
.loggedout {
  &--container {
    margin-top: 50px; }
  &--title {
    text-align: center; } }

.homepage {
  &--col {
    margin-top: 20px;
    width: 66%;
    @include media-breakpoint-only(xs) {
      width: 100%; } }

  &--bulletinboard {
    margin-top: 40px; }
  &--announcements-title {
    small {
      font-size: 14px;
      font-weight: bold; } }
  &--bulletin-title {
    small {
      font-size: 14px;
      font-weight: bold; } } }
.side-nav {
  &--container {
    padding-left: 100px;
    width: 36%;
    margin-right: 0;

    float: right;
    @include media-breakpoint-only(xs) {
      float: none;
      padding-left: 0;
      margin-left: 30px;
      width: 100%;
      margin-top: 0; } }
  &--nav {
    flex-direction: column;
    margin-top: 20px;

    @include media-breakpoint-only(xs) {
      text-align: center;
      flex-direction: row;
      margin-top: 20px; } }
  &--link {
    background-color: $steelblue;
    width: 250px;
    color: $jet;
    // &:hover
    //// background-color: $steelblue
    //// color: $jet
    //// opacity: 40%
    //// border: solid $jet .5px
    //// background-color: white
    @include media-breakpoint-only(md) {
      width: 175px; }
    @include media-breakpoint-only(sm) {
      width: 140px; }
    @include media-breakpoint-only(xs) {
      display: inline-block;
      width: auto; } }
  &--notification-unread {
    color: red; } }
.signupform {
  &--container {
    margin-top: 50px;
    width: 80%;
    &--wrapper {
      display: flex;
      justify-content: center; } }
  &--row {
    margin-right: 100px;
    @include media-breakpoint-only(md) {
      margin-right: 65px; }
    @include media-breakpoint-only(sm) {
      margin-right: auto; }
    @include media-breakpoint-only(xs) {
      margin-right: auto; } } }
.signin {
  &--title {
    text-align: center; }
  &--wrapper {
    width: 80%;
    margin-top: 30px; }
  &--container {}
  &--login-form {
    display: flex;
    justify-content: flex-start;
    @include media-breakpoint-only(xs) {
      display: flex;
      justify-content: center; } }
  &--icon {
    width: 250px;
    height: auto;
    margin-top: 20px;
    @include media-breakpoint-only(md) {
      width: 250px; }

    @include media-breakpoint-only(sm) {
      width: 250px; }

    @include media-breakpoint-only(xs) {
      width: 200px;
      height: 200px;
      margin-top: -20px; } } }
.loginform {
  &--wrapper {
    width: 100%; }
  &--form {
    width: 100%;
    // margin-top: 20px
    @include media-breakpoint-only(md) {
      width: 80%;
      margin-top: 0; }
    @include media-breakpoint-only(sm) {
      width: 100%;
      margin-top: 0; }
    @include media-breakpoint-only(xs) {
      width: 100%;
      margin-top: -20px; } }
  &--div {
    margin-top: 20px; } }

.announcements {
  &--container {
    margin-top: 20px; } }

.post-announcement {
  &--container {
    margin-top: 30px; }
  &--form {
    width: 66%;
    @include media-breakpoint-only(xs) {
      width: 100%; } } }

.view-announcement {
  &--date {
    font-style: italic; }
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: #FFFFFF;
    font-weight: bold;
    position: absolute;
    top: 25px;
    right: 20px;
    &:hover {
      background-color: $red;
      color: $white; } } }
.bulletin {
  &--container {
    margin-top: 20px; } }

.post-bulletin {
  &--container {
    margin-top: 30px; }
  &--form {
    width: 66%;
    @include media-breakpoint-only(xs) {
      width: 100%; } } }
.view-bulletin {
  &--date {
    font-style: italic; }
  &--author-title {
    small {
      font-size: 14px; } }
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: #FFFFFF;
    font-weight: bold;
    position: absolute;
    top: 25px;
    right: 20px;
    &:hover {
      // background-color: $grey
      color: darkred; } } }
.notifications {
  &--container {
    margin-top: 20px; } }
.view-notification {
  &--date {
    font-style: italic; }
  &--author-title {
    small {
      font-size: 14px; } }
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: #FFFFFF;
    font-weight: bold;
    position: absolute;
    bottom: 35px;
    right: 5px;
    &:hover {
      // background-color: $grey
      color: darkred; } }
  &--read-btn {
    color: $darkblue;
    // border: 1px solid $red;
    border: none;
    background-color: #FFFFFF;
    border-radius: 2em;
    position: absolute;
    right: 5px;
    bottom: 10px;
    @include media-breakpoint-only(xs) {
      position: relative;
      right: 0px;
      bottom: 0px; }

    &:hover {
      background-color: $grey;
      color: $white; } }

  &--hide-btn {
    color: $darkblue;
    // border: 1px solid $red;
    border: none;
    background-color: #FFFFFF;
    // font-weight: bold
    position: absolute;
    top: 50px;
    right: 20px;
    border-radius: 2em;
    &:hover {
      background-color: $grey;
      color: $white; } } }
.post-messages {
  &--container {
    margin-top: 30px; }
  &--form {
    width: 66%;
    @include media-breakpoint-only(xs) {
      width: 100%; } }
  &--success {
    margin-left: 20px;
    display: inline-block;
    font-weight: bold;
    color: $jet; } }

.messages {
  &--container {
    margin-top: 20px; } }
.view-messages {
  &--date {
    font-style: italic; }
  &--author-title {
    small {
      font-size: 14px; } }
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: $lightgrey;
    font-weight: bold;
    position: absolute;
    top: 50px;
    right: 20px;
    &:hover {
      // background-color: $grey
      color: darkred; } }
  &--read-btn {
    color: $darkblue;
    // border: 1px solid $red;
    border: none;
    background-color: $lightgrey;
    border-radius: 2em;
    &:hover {
      background-color: $grey;
      color: $white; } }
  &--hide-btn {
    color: $darkblue;
    // border: 1px solid $red;
    border: none;
    background-color: $lightgrey;
    // font-weight: bold
    // position: absolute
    // top: 50px
    // right: 20px
    border-radius: 2em;
    &:hover {
      background-color: $grey;
      color: $white; } } }
.profile {
  &--container {
    margin-top: 20px; }
  &--form {
    width: 66%;
    @include media-breakpoint-only(xs) {
      width: 100%; } } }
.profileform {
  &--upload-btn {
    display: inline-block;
    margin-left: 10px; }
  &--upload-button {
    background-color: $grey;
    color: $jet;
    border-color: $grey;
    &:hover {
      background-color: $jet;
      color: $grey;
      border-color: $jet; }
    @include media-breakpoint-only(xs) {
      margin-top: 20px;
      display: block; } }

  &--imageupload {
    width: 50%; }
  &--delete-img {
    height: 10px;
    color: red;
    border: none;
    top: 10px;
    right: -60px;
    position: absolute; } }
.profile-image {
  height: 100px;
  width: auto;
  margin-left: 25px;
  margin-top: 20px; }
.custom-file-label {
  width: 70%;
  @include media-breakpoint-only(xs) {
    width: 80%; } }
.custom-file-input {
  width: 70%; }
.contacts {
  &--container {
    margin-top: 20px; } }
.contactcard {
  &--card {
    background-color: $lightgrey;
    border-radius: 1em; }
  &--profile-image {
    height: 40px;
    width: auto;
    position: absolute;
    bottom: 10px;
    left: 20px; }
  &--messages-link {
    position: relative;
    left: 50px;
    margin-left: 10px; }
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: $lightgrey;
    font-weight: bold;
    position: absolute;
    top: 45px;
    right: 20px; } }

.files {
  &--container {
    margin-top: 20px; }
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: $lightgrey;
    font-weight: bold;
    position: absolute;
    top: 45px;
    right: 20px;
    @include media-breakpoint-only(xs) {
      top: 45px; } } }
.file {
  &--delete-btn {
    color: $red;
    // border: 1px solid $red;
    border: none;
    background-color: $white;
    font-weight: bold;
    position: absolute;
    margin-left: 5px; } }
.reset {
  &--container {
    margin-top: 20px;
    width: 80%; }
  &--form {
    width: 60%;
    align-content: center; } }
.progressbar {
  height: 1rem;
  width: 0px;
  background-color: $jet; }
.file-upload {
  box-shadow: 2px 2px 2px 2px #ccc;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem; }
.view-files {}

.request {
  &--note-form {
    width: 66%;
    @include media-breakpoint-only(xs) {
      width: 100%; } }
  &--read-btn {
    color: $darkblue;
    // border: 1px solid $red;
    border: none;
    background-color: $lightgrey;
    border-radius: 2em;

    &:hover {
      background-color: $grey;
      color: $white; } } }

.upbutton {
  background-color: $grey;
  color: $jet;
  border-color: $grey;
  width: 5rem;
  &:hover {
    background-color: $jet;
    color: $grey;
    border-color: $jet;

    // padding: .5rem
    // background-color: #2767e9
    // color: aliceblue
    // font-size: 1rem
 } }    // cursor: pointer
.about {
  &--container {
    margin-top: 20px; }
  &--title {
    margin-left: 20px; }
  &--col {
    width: 66%; } }
.footer {
  &--container {
    background-color: #343a40;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 0; }
  &--about {
    margin-top: 5px; }
  &--aboutlink {
    color: rgba(255, 255, 255, 0.5); } }
